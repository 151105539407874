<template>
    
    <div class="container" v-if="isLoading && mnemonicSeed.length < 1">
      <LoadingFullPage/>
    </div>

    <ShowMnemonic :mnemonic="mnemonicSeed" v-if="mnemonicSeed.length > 0 && isLoading==false"/>
    
    <div class="container" v-if="mnemonicSeed.length < 1 && isLoading==false">

      <div class="row marginTop white">

        <div class="col s12 center green white-text">
          <h4>Bienvenido!</h4>
        </div>

        <div class="col s12 left">
          <p>
            Para generar nuevas claves, se necesita entropia! 
          </p>
          <p class = "left" id = "instructions">
            Haga Click en el rectangulo naranjado <b class = "orange-text">{{ clickCounter }}</b> veces
          </p>
        </div>

      </div>

      <div class = "row">
        <div class="col s12 center orange entropyBox" @click="getXY($event)"></div>
      </div>

    </div>

</template>

<script>

import LoadingFullPage from '@/components/UI/LoadingFullPage'
import ShowMnemonic from '@/components/seed/ShowMnemonic'
import { ref } from '@vue/reactivity'
import { generateEntropy } from '@/composables/entropy'
import { encryptKey } from '@/composables/encryption'
import useBoilerplate from '@/composables/useBoilerplate';
import { post } from '@/composables/httpCalls'
export default {
  
  components: { LoadingFullPage, ShowMnemonic },
  
  setup(){

    const {router, isLoading, error } = useBoilerplate(false);
    
    const clickCounter = ref(10);
    const mnemonicSeed = ref([]);

    const randomState = {
      lastX: 0,
      lastY: 0,
      points: []
    }

    const manageEncryptedKeys = async (xPriv, xPub, mnemonic) => {
      
      const url = "/api/saveXpub";
      const vars = { xPub: xPub };

      try {

        const res = await post(url,vars);

        if(res.status == 200){
          
          const myResponse = await res.json();
          const encryptedKey = encryptKey(xPriv, myResponse.encKey);

          if(encryptKey == false){
            isLoading.value = false;
            return error.value = "Usted No Esta Logueado"  
          }
          
          //Manage xPriv Locally
          localStorage.setItem(myResponse.alias, encryptedKey);
          //Manage xPub Locally
          sessionStorage.setItem('xPub', xPub);

          mnemonicSeed.value = mnemonic.split(' ');

          isLoading.value = false;

        }else if(res.status == 409){
          isLoading.value = false;
          return router.push({name: 'Auth'})
        }else if(res.status == 500){
          isLoading.value = false;
          return error.value = "Error de Sistema"  
        }else{
          isLoading.value = false;
          return error.value = "Error"  
        }

      } catch (err) {
        console.log(err);
        isLoading.value = false;
        return error.value = "Error de Conexion"  
      }

    }

    const getXY = async (e) => {

      try{
          
        clickCounter.value--;
        
        if(clickCounter.value < 1){
            
            isLoading.value = true;

            //Mix final entropy numbers with letters
            const finalEntropy = generateEntropy(randomState);

            const keys = window.generateKeyPair(finalEntropy);
            
            return manageEncryptedKeys(keys.xPriv, keys.xPub, keys.mnemonic);

        }
        
        if(e.clientX === randomState.lastX && e.clientY === randomState.lastY){
            clickCounter.value++;
            return;
        }
        
        randomState.lastX = e.clientX;
        randomState.lastY = e.clientY;
        
        //Multiply X by Y and add product to array
        const randomProduct = e.clientX * e.clientY;
        randomState.points.push(randomProduct);
      
      }catch(err){
          error.value = err.message;
      }

    }

    return { isLoading, clickCounter, mnemonicSeed, getXY };
  }

}

</script>

<style scoped>
  .entropyBox{
    height: 400px;
  }
</style>