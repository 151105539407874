<template>
    <div class="col s6 m4 l3 center-align">
        <h6 class = 'white-text'>{{index + 1}}. {{ word }} </h6>
    </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
export default {
    props: ['word', 'index'],
    
}
</script>

<style scoped>
    .white-text{
        padding-top: 10px;
        padding-bottom: 10px;
    }
</style>