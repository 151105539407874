<template>

  <div class = "container">

      <div class="row grey darken-2" id = "mnemonicSeed">
          
          <br>
          
          <div class="col s12 center-align orange white-text">
            <h5>Semilla</h5>
          </div>
          <div class="col s12 grey lighten-4 red-text left-align">
            <h6 class = 'margin'><b>IMPORTANTE:</b> Escriba las 12 palabras abajo en un papel y guárdelo en un lugar seguro</h6>
          </div>
          
          <div v-for="(word, index) in mnemonic" :key="index">
            <SingleMnemonicWord :word="word" :index="index"/>
          </div>
          
          <div class="col s12 center-align grey darken-2">
            <router-link :to="{name: 'Exchange'}" class = "btn white-text orange margin">
              <i class="fas fa-check"></i>
            </router-link>
          </div>

      </div>
  
  </div>
        
</template>

<script>
import SingleMnemonicWord from '@/components/seed/SingleMnemonicWord'
export default {
    components: { SingleMnemonicWord },
    props: ['mnemonic']
}
</script>