const getRandomArbitrary = (min, max) => {
    const random = Math.random() * (max - min) + min;
    return Math.round(random);
  }

export const generateEntropy = (randomState) => {
    let entropy = '';
    for(let i = 0; i < 8; i++){
        const point = randomState.points[getRandomArbitrary(0, 8)];
        const multiplier = randomState.points[getRandomArbitrary(0, 8)];
        const multipliedPoint = String(point * multiplier);
        const bites = multipliedPoint[2] + multipliedPoint[3] + multipliedPoint[4] + multipliedPoint[5];
        entropy += bites;
    }
    return entropy;
}